exports.onClientEntry = () => {
  if (
    process.env.GATSBY_IN_SITE_MAINTENANCE === "true" &&
    window.location.pathname !== "/maintenance/"
  ) {
    window.location = "/maintenance/";
  }
};

exports.onRouteUpdate = () => {
  if (
    process.env.GATSBY_IN_SITE_MAINTENANCE === "true" &&
    window.location.pathname !== "/maintenance/"
  ) {
    window.location = "/maintenance";
  }
};
